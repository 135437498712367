import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import axios from "axios";

const NewCustomer = (props) => {
  let params = useParams();
  let navigate = useNavigate();

  //tracking fields
  const [firstName, setFirstName] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [birthDay, setBirthDay] = useState("");
  const [birthMonth, setBirthMonth] = useState("");
  const [birthYear, setBirthYear] = useState("");

  useEffect(() => {
    if (params.phoneNumber) {
      setphoneNumber(params.phoneNumber);
    }
  }, [params.phoneNumber]);

  const birthYearsOptions = () => {
    let startYear = 1920;
    let currentYear = new Date().getFullYear();
    let options = [];
    for (var year = startYear; year <= currentYear; year++) {
      options.push(year);
    }

    return options;
  };

  const birthDayOptions = () => {
    let options = [];

    for (var day = 1; day <= 31; day++) {
      var paddedDay = day.toString().padStart(2, "0");
      options.push(paddedDay);
    }

    return options;
  };

  let birthMonthOptions = [
    { value: "01", name: "January" },
    { value: "02", name: "February" },
    { value: "03", name: "March" },
    { value: "04", name: "April" },
    { value: "05", name: "May" },
    { value: "06", name: "June" },
    { value: "07", name: "July" },
    { value: "08", name: "August" },
    { value: "09", name: "September" },
    { value: "10", name: "October" },
    { value: "11", name: "November" },
    { value: "12", name: "December" },
  ];

  //add new customer
  const addNewCustomer = async (newCustomer, redirectUrl) => {
    await axios
      .post(props.apiURLNext + "add_new_customer", newCustomer)
      .then((response) => {
        //to do
        if (redirectUrl.indexOf("/final_screen") !== -1) {
          navigate(redirectUrl);
        } else {
          navigate(`${redirectUrl}/${response.data.new_customer_id}`);
        }
      })
      .catch((error) => {
        console.log("Error ========>", error);
      });
  };

  const goToBookServiceFunc = (e) => {
    e.preventDefault();
    if (phoneNumber && firstName) {
      //phoneNumber is a must
      //prep form data

      const formData = {
        first_name: firstName,
        phone_number: phoneNumber,
        email: email,
        dob:
          birthDay && birthMonth && birthYear
            ? `${birthYear}-${birthMonth}-${birthDay}`
            : null,
      };

      addNewCustomer(formData, "/pick_services");
    } else {
      alert(
        "Please enter your personal information before selecting services!"
      );
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();

    if (phoneNumber && firstName) {
      //phoneNumber is a must
      //prep form data

      const formData = {
        first_name: firstName,
        phone_number: phoneNumber,
        email: email,
        dob:
          birthDay && birthMonth && birthYear
            ? `${birthYear}-${birthMonth}-${birthDay}`
            : null,
      };

      addNewCustomer(formData, "/final_screen");
    } else {
      alert("Name and phone number is required to complete check-in!");
    }
    console.log("form submiteed");
  };

  return (
    <div className="appContainer__newCustomer">
      <Typography
        style={{ textAlign: "center", fontWeight: "100" }}
        mt={3}
        variant="h6"
        gutterBottom
        component="div"
      >
        Please enter your information to complete check-in
      </Typography>
      <form noValidate autoComplete="off" onSubmit={onSubmit}>
        <Stack direction="row" spacing={2} mb={2}>
          <TextField
            fullWidth
            required
            label="Name"
            variant="filled"
            type="text"
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Stack>
        <Stack direction="row" spacing={2} mb={2}>
          <FormControl fullWidth>
            <InputLabel id="birth-day-select-label">Birth Day</InputLabel>
            <Select
              labelId="birth-day-select-label"
              id="birth-day-select"
              value={birthDay}
              label="Birth Day"
              onChange={(e) => setBirthDay(e.target.value)}
            >
              {birthDayOptions().map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="birth-month-select-label">Birth Month</InputLabel>
            <Select
              labelId="birth-month-select-label"
              id="birth-month-select"
              value={birthMonth}
              label="Birth Month"
              onChange={(e) => setBirthMonth(e.target.value)}
            >
              {birthMonthOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="birth-year-select-label">Birth Year</InputLabel>
            <Select
              labelId="birth-year-select-label"
              id="birth-year-select"
              value={birthYear}
              label="Birth Year"
              onChange={(e) => setBirthYear(e.target.value)}
            >
              {birthYearsOptions().map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <div style={{ marginBottom: "16px" }}>
          <TextField
            fullWidth
            required
            label="Phone Number"
            variant="outlined"
            helperText=""
            defaultValue={params.phoneNumber}
            onChange={(e) => setphoneNumber(e.target.value)}
          />
        </div>
        <div style={{ marginBottom: "16px" }}>
          <TextField
            fullWidth
            label="Email (Optional)"
            variant="outlined"
            type="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <ButtonGroup
          variant="contained"
          style={{
            margin: "15px 0",
            display: "flex",
            flexFlow: "column",
            gap: "15px",
            justifyContent: "center",
          }}
        >
          <Button
            style={{ padding: "15px" }}
            className="btn-success"
            type="button"
            onClick={(e) => goToBookServiceFunc(e)}
            endIcon={<ArrowForwardIosIcon />}
          >
            Select Services
          </Button>
          <Button
            style={{ padding: "15px" }}
            type="button"
            color="error"
            onClick={(e) => {
              e.preventDefault();
              window.location.href = "/";
            }}
            startIcon={<ArrowBackIosNewIcon />}
          >
            Go Back
          </Button>
        </ButtonGroup>
      </form>
    </div>
  );
};

export default NewCustomer;
