import React from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import BackspaceIcon from "@mui/icons-material/Backspace";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { version as app_version } from "./../../package.json";

const Main = (props) => {
  const [inputPhone, setInputPhone] = useState("");
  let navigate = useNavigate();

  const processKeyPad = (keyPad) => {
    if (inputPhone.length < 10) {
      var tempPhone = inputPhone + "" + keyPad;
      setInputPhone(tempPhone);
    }
  };

  const clearKeyPad = () => {
    if (inputPhone) {
      var tempPhone = inputPhone.substring(0, inputPhone.length - 1);
      setInputPhone(tempPhone);
    }
  };

  useEffect(() => {
    switch (props.customerStatusCode) {
      case 1:
        navigate("new_customer/" + inputPhone);
        break;
      case 2:
        navigate("existing_customer/" + inputPhone);
        break;
      default:
        navigate("/");
        break;
    }
  }, [props.customerStatusCode]);

  return (
    <div className="appContainer__main">
      <div
        style={{
          textTransform: "uppercase",
          textAlign: "center",
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
        }}
      >
        <span style={{ fontSize: "3rem" }}>WELCOME</span>
        {props.store.logo ? (
          <img
            src={`${props.apiURL}assets/images/${props.store.logo}`}
            alt="store logo"
            style={{ width: "300px", margin: "25px 0" }}
          />
        ) : (
          ""
        )}
        <span style={{ fontSize: "2rem" }}>{props.store.business_name}</span>
        <br></br>
      </div>
      <div>
        <Typography
          style={{ textAlign: "center", fontWeight: "400" }}
          variant="h3"
          gutterBottom
          component="div"
        >
          Please check in here
        </Typography>
        <div>
          <span className="appContainer__main__phonePlaceholder">
            {inputPhone ? inputPhone : "Enter your mobile #"}
          </span>
        </div>
        <div className="appContainer__main__keyPadGrp">
          <Button onClick={() => processKeyPad(1)}>1</Button>
          <Button onClick={() => processKeyPad(2)}>2</Button>
          <Button onClick={() => processKeyPad(3)}>3</Button>
          <Button onClick={() => processKeyPad(4)}>4</Button>
          <Button onClick={() => processKeyPad(5)}>5</Button>
          <Button onClick={() => processKeyPad(6)}>6</Button>
          <Button onClick={() => processKeyPad(7)}>7</Button>
          <Button onClick={() => processKeyPad(8)}>8</Button>
          <Button onClick={() => processKeyPad(9)}>9</Button>
          <Button onClick={() => clearKeyPad()}>
            <BackspaceIcon />
          </Button>
          <Button onClick={() => processKeyPad(0)}>0</Button>
          <Button
            className="appContainer__main__keyPadGrp--next"
            onClick={() => {
              if (inputPhone.length === 10) {
                props.processCustomerPhoneInput(inputPhone);
              } else {
                toast.error("Invalid phone number. Please try again!", {
                  position: "top-center",
                  autoClose: 1500,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                });
              }
            }}
          >
            Next
          </Button>
        </div>
        <Typography
          className="consentText"
          style={{ textAlign: "center" }}
          mt={3}
          variant="caption"
          gutterBottom
          component="div"
        >
          Version {app_version}
        </Typography>
      </div>
    </div>
  );
};

export default Main;
