import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";

const PickTimes = (props) => {
  let params = useParams();
  let navigate = useNavigate();

  const finalizeBooking = async (event) => {
    event.stopPropagation();

    //manipulate the start and end times before passing it to api
    let selectedStartTime = event.target.getAttribute("data-start_time");
    let selectedEndTime = event.target.getAttribute("data-end_time");

    let prevEndTime = "";
    let finalSelectedServices = props.selectedServices;
    finalSelectedServices.forEach((rowItem) => {
      if (prevEndTime === "") {
        rowItem.start_time = selectedStartTime;
        rowItem.end_time = moment(selectedStartTime, "HH:mm")
          .add(rowItem.duration, "minutes")
          .format("HH:mm");
      } else {
        rowItem.start_time = prevEndTime;
        rowItem.end_time = moment(prevEndTime, "HH:mm")
          .add(rowItem.duration, "minutes")
          .format("HH:mm");
      }
      prevEndTime = rowItem.end_time;
    });

    const formData = {
      booked_services: JSON.stringify(props.selectedServices),
      customer_id: params.customerId,
      staff_id: props.selectedStaff,
    };

    await axios
      .post(props.apiURLNext + "employees/finalize_walkin_booking", formData)
      .then((response) => {
        console.log(response);
        if (response.data.code === 200) {
          navigate("/final_screen");
        } else {
          toast.error(
            "Something went wrong! Please ask for help at reception!",
            {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      })
      .catch((error) => {
        console.log("Error ========>", error);
      });
  };

  return (
    <div className="appContainer__pickTimeSlot">
      <Typography
        style={{ textAlign: "center" }}
        mt={3}
        variant="h5"
        gutterBottom
        component="div"
      >
        Please select a time you want to start the service
      </Typography>
      <div className="booking-card-container booking-time-container">
        {props.availableTimeSlots.map((rowItem) => (
          <div
            key={rowItem.start}
            className="booking-card-item time-block-item"
            data-start_time={rowItem.start}
            data-end_time={rowItem.end}
            onClick={finalizeBooking}
          >
            <span
              data-start_time={rowItem.start}
              data-end_time={rowItem.end}
              onClick={finalizeBooking}
            >
              {rowItem.start_display}
            </span>
            <ArrowForwardIosIcon
              data-start_time={rowItem.start}
              data-end_time={rowItem.end}
              onClick={finalizeBooking}
            />
          </div>
        ))}
      </div>
      <ButtonGroup
        variant="contained"
        style={{
          margin: "15px 0",
          display: "flex",
          flexFlow: "column",
          gap: "15px",
          justifyContent: "center",
        }}
      >
        <Button
          style={{ padding: "15px" }}
          className="btn-success"
          onClick={(e) => {
            navigate("/pick_staff/" + params.customerId);
          }}
          startIcon={<ArrowBackIosNewIcon />}
        >
          Back to Staff List
        </Button>
        <Button
          style={{ padding: "15px" }}
          color="error"
          onClick={(e) => {
            window.location.href = "/";
          }}
          endIcon={<ArrowForwardIosIcon />}
        >
          Exit Now
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default PickTimes;
