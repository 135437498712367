import React, { useEffect } from "react";
import Alert from "@mui/material/Alert";

const FinalScreen = () => {
  useEffect(() => {
    setTimeout(function () {
      window.location.href = "/";
    }, 1000);
  }, []);

  return (
    <div className="appContainer__main__finalScreen">
      <Alert style={{ marginBottom: "15px" }} severity="success">
        <p style={{ fontSize: "30px" }}>
          Thank you and enjoy your services with us today!
        </p>
      </Alert>
    </div>
  );
};

export default FinalScreen;
