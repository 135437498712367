import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { toast } from "react-toastify";
import moment from "moment";
import "react-toastify/dist/ReactToastify.css";

const PickStaff = (props) => {
  let params = useParams();
  let navigate = useNavigate();

  function generateTimes(openningTime = "09:00 AM", closingTime = "09:00 PM") {
    const start = moment(openningTime, "hh:mm A");
    const end = moment(closingTime, "hh:mm A");
    const interval = 15; // 15 minutes

    const times = [];
    for (let m = start; m.isSameOrBefore(end); m.add(interval, "minutes")) {
      times.push(m.format("hh:mm A"));
    }

    return times;
  }

  const goToPickTime = async (event) => {
    event.stopPropagation();

    let totalServiceDuration = props.selectedServices
      .map((item) => parseInt(item.duration))
      .reduce((accumulator, currentValue) => accumulator + currentValue, 0);

    let storeHours = JSON.parse(props.store.custom_opening_hours);
    let findStoreHour = storeHours.find(
      (item) => item.day === moment().format("ddd")
    );

    let timeBlocks = generateTimes(
      findStoreHour.times.open,
      findStoreHour.times.close
    );
    let startEndTimeBlocks = [];
    timeBlocks.forEach(function (rowItem) {
      startEndTimeBlocks.push({
        start: moment(rowItem, "hh:mm A").format("HH:mm"),
        end: moment(rowItem, "hh:mm A")
          .add(totalServiceDuration, "minutes")
          .format("HH:mm"),
        date: moment().format("YYYY-MM-DD"),
        day: moment().format("e"),
        duration: totalServiceDuration,
        employee_id: event.target.getAttribute("data-id"),
        start_display: rowItem,
        end_display: moment(rowItem, "hh:mm A")
          .add(totalServiceDuration, "minutes")
          .format("hh:mm A"),
      });
    });

    const formData = {
      time_slots: JSON.stringify(startEndTimeBlocks),
      selected_skill_staff_id: event.target.getAttribute("data-id"),
    };

    axios
      .post(props.apiURLNext + "employees/check_booking_slots", formData)
      .then((response) => {
        if (response.data.length > 0) {
          let filterAvailableSlots = [];
          response.data.forEach((item) => {
            if (
              item.available === true &&
              item.start >= moment().format("HH:mm")
            ) {
              filterAvailableSlots.push(item);
            }
          });
          props.setAvailableTimeSlots(filterAvailableSlots);
          props.setSelectedStaff(event.target.getAttribute("data-id"));
          navigate(`/pick_time/${params.customerId}`);
        } else {
          toast.error(
            "This staff is not available. Please select someone else!",
            {
              position: "top-center",
              autoClose: 1500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            }
          );
        }
      })
      .catch((error) => {
        console.log("Error ========>", error);
      });
  };

  return (
    <div className="appContainer__pickStaffs">
      <Typography
        style={{ textAlign: "center" }}
        mt={3}
        variant="h5"
        gutterBottom
        component="div"
      >
        Please choose any staff to do your service
      </Typography>
      <div className="booking-card-container staff-container">
        {props.availableStaffs.map((staffRow) => (
          <div
            data-id={staffRow.id}
            data-name={staffRow.staff_name}
            onClick={goToPickTime}
            key={staffRow.id}
            className="booking-card-item staff-item"
          >
            <img
              data-id={staffRow.id}
              data-name={staffRow.staff_name}
              onClick={goToPickTime}
              src={`${props.apiURL}assets/images/staff_avatars/${staffRow.avatar}`}
              alt=""
            />
            <span
              className="staff-name"
              data-id={staffRow.id}
              data-name={staffRow.staff_name}
              onClick={goToPickTime}
            >
              {staffRow.staff_name}
            </span>
            <span>
              <ArrowForwardIosIcon />
            </span>
          </div>
        ))}
      </div>
      <ButtonGroup
        variant="contained"
        style={{
          margin: "15px 0",
          display: "flex",
          flexFlow: "column",
          gap: "15px",
          justifyContent: "center",
        }}
      >
        <Button
          style={{ padding: "15px" }}
          className="btn-success"
          onClick={(e) => {
            navigate("/pick_services/" + params.customerId);
          }}
          startIcon={<ArrowBackIosNewIcon />}
        >
          Back to Service List
        </Button>
        <Button
          style={{ padding: "15px" }}
          color="error"
          onClick={(e) => {
            window.location.href = "/";
          }}
          endIcon={<ArrowForwardIosIcon />}
        >
          Decide Later
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default PickStaff;
