import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Typography from "@mui/material/Typography";
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/swiper-bundle.min.css";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PickServices = (props) => {
  let params = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    let swiper = new Swiper(".swiper", {
      modules: [Navigation, Pagination],
      // Optional parameters
      direction: "horizontal",
      spaceBetween: 20,
      slidesPerView: 2,
      loop: false,
      cssMode: true,
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      breakpoints: {
        700: {
          slidesPerView: 2,
        },
        768: {
          slidesPerView: 3,
        },
      },
    });
  }, []);

  //service time format
  function time_convert(num) {
    var minutes = num;
    var hours = Math.floor(minutes / 60);
    var remainingMinutes = minutes % 60;
    var text = "";

    if (hours > 0) {
      if (remainingMinutes > 0) {
        text = hours + "h " + remainingMinutes + "min";
      } else {
        text = hours + "h";
      }
    } else {
      text = remainingMinutes + "min";
    }
    return text;
  }

  //format price row
  function formatPrice(price) {
    if (!isNaN(parseFloat(price)) && isFinite(price)) {
      return (
        "$" +
        parseFloat(price)
          .toFixed(2)
          .replace(/\d(?=(\d{3})+\.)/g, "$&,")
      );
    }
    return price; // If not a valid number, return as it is
  }

  //handle staff pick navigation
  const goToStaffPickFunc = async () => {
    if (props.selectedServices.length === 0) {
      toast.error("Please select services you want to do today!", {
        position: "top-center",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      const formData = {
        booked_services: JSON.stringify(props.selectedServices),
      };

      await axios
        .post(props.apiURLNext + "employees/employees_by_skill", formData)
        .then((response) => {
          if (response.data.staffs.length > 0) {
            props.setAvailableStaffs(response.data.staffs);
            props.setSelectedServices(response.data.services);
            navigate("/pick_staff/" + params.customerId);
          } else {
            toast.error(
              "No staffs are available at the moment. Please check with the reception!",
              {
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              }
            );
          }
        })
        .catch((error) => {
          console.log("Error ========>", error);
        });
    }
  };

  //handle services check items
  const handleCheckboxChange = (event) => {
    var checkboxes = document.querySelectorAll(
      'input[type=checkbox][name="service_check_item[]"]:checked'
    );
    var values = [];
    checkboxes.forEach(function (checkbox) {
      values.push({
        id: checkbox.getAttribute("data-id"),
        name: checkbox.value,
        duration: checkbox.getAttribute("data-duration"),
      });
    });
    props.setSelectedServices(values);
  };

  //handle slider click to scroll to next view
  const handleSliderItemClick = (event) => {
    let eventTargetId = event.target.getAttribute("data-target");
    let cumulativeHeight = 0;
    const scrollServiceContainer = document.querySelector(
      ".booking-service-category-content"
    );
    scrollServiceContainer
      .querySelectorAll(".booking-service-category-content-item")
      .forEach((item) => {
        if (item.id === eventTargetId) {
          scrollServiceContainer.scrollTo({
            top: cumulativeHeight,
            behavior: "smooth",
          });
          return false; // Stop iterating once target child is reached
        }
        cumulativeHeight += parseFloat(item.offsetHeight);
      });
  };

  return (
    <div className="appContainer__pickServices">
      <Typography
        style={{ textAlign: "center" }}
        mt={3}
        variant="h5"
        gutterBottom
        component="div"
      >
        What services are you doing today?
      </Typography>
      <div className="swiper">
        <div className="swiper-wrapper booking-service-category-container">
          {props.serviceApi.map((serviceCategory) => (
            <div key={serviceCategory.id} className="swiper-slide">
              <div
                className="booking-service-category-item"
                data-target={`service-category-${serviceCategory.id}`}
                onClick={handleSliderItemClick}
              >
                {serviceCategory.name}
              </div>
            </div>
          ))}
        </div>
        <div className="swiper-button-prev"></div>
        <div className="swiper-button-next"></div>
      </div>
      <div className="booking-service-category-content">
        {props.serviceApi.map((serviceCategory) => (
          <div
            id={`service-category-${serviceCategory.id}`}
            key={`service-category-${serviceCategory.id}`}
            className="booking-service-category-content-item"
          >
            <h3 className="category-heading">{serviceCategory.name}</h3>
            {serviceCategory.description ? (
              <div
                style={{ marginBottom: "25px" }}
                dangerouslySetInnerHTML={{
                  __html: serviceCategory.description,
                }}
              ></div>
            ) : (
              ""
            )}

            <div className="booking-service-category-items">
              {serviceCategory.services.map((serviceItem) => (
                <div
                  key={`service-item-${serviceItem.id}`}
                  className="service-item"
                >
                  <input
                    type="checkbox"
                    name="service_check_item[]"
                    data-id={serviceItem.id}
                    data-duration={serviceItem.approximate_hour}
                    id={`service_check_item_${serviceItem.id}`}
                    value={serviceItem.name}
                    defaultChecked={
                      props.selectedServices
                        .map((item) => parseInt(item.id))
                        .includes(serviceItem.id)
                        ? true
                        : false
                    }
                    onClick={handleCheckboxChange}
                  />
                  <div className="service-item-name">
                    <label
                      className="service-item-name-heading"
                      htmlFor={`service_check_item_${serviceItem.id}`}
                    >
                      {serviceItem.name}
                    </label>
                    {isNaN(serviceItem.approximate_hour) ? (
                      ""
                    ) : (
                      <span className="service-duration">
                        {time_convert(serviceItem.approximate_hour)}
                      </span>
                    )}
                  </div>
                  <span className="service-price">
                    {formatPrice(serviceItem.price)}
                  </span>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
      <ButtonGroup
        variant="contained"
        style={{
          margin: "15px 0",
          display: "flex",
          flexFlow: "column",
          gap: "15px",
          justifyContent: "center",
        }}
      >
        <Button
          style={{ padding: "15px" }}
          className="btn-success"
          onClick={goToStaffPickFunc}
          endIcon={<ArrowForwardIosIcon />}
        >
          Choose Technician
        </Button>
        <Button
          style={{ padding: "15px" }}
          color="error"
          onClick={(e) => {
            window.location.href = "/";
          }}
          endIcon={<ArrowForwardIosIcon />}
        >
          Decide Later
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default PickServices;
